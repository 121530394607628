export interface IProductList {
    groupTypeProductsData: Array<any>,
    typeProductsData: Array<any>,
    filterSetData: any,
    productGroupsData: Array<any>,
    activeTypeData: number | string,
    productsData: any,
    branchDatas: Array<any>
}

export const IOrderType = {
    sell: 0,
    rent: 1,
    orderCalendar: 2
}